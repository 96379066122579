import { Waypoint } from "vue-waypoint";
import Table from "@/components/Table.vue";

export default {
  name: "ScenarioDiagram",
  props: {
    data: Object,
  },
  components: {
    Waypoint,
    Table,
  },
  created() {},
};

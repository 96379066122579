import "./assets/styles/main.scss";

import Header from "@/components/Header.vue";

export default {
  name: "App",
  components: {
    Header,
  },
  methods: {
    backToTop() {
      document.getElementById("app").scrollIntoView({ behavior: "smooth" });
    },
  },
};

import { Waypoint } from "vue-waypoint";

let player = null;

export default {
  name: "Video",
  props: {
    data: Object,
    isOdd: Boolean,
  },
  components: {
    Waypoint,
  },
  data() {
    return {
      myPlayer: {},
      playing: false,

      cutter: true,

      videoOptions: {
        nativeControlsForTouch: false,
        logo: { enabled: false },
        autoplay: false,
        controls: true,
        hotKeys: {
          enableVolumeScroll: false,
          enableFullscreen: false,
        },
        poster: "",
        plugins: {
          /*
          appInsights: {
            'debug': true
          }
          */
        },
      },

      videoVisible: false,
      _player: null,
      player: null,
    };
  },
  created() {},
  mounted() {
    this.videoInit();
  },
  beforeUnmount() {
    //this.myPlayer.dispose();
  },
  methods: {
    videoInit() {
      /*
      let player = this.myPlayer;

      // eslint-disable-next-line no-undef
      player = amp(this.data.video.id, this.videoOptions, function () {
        player.addEventListener("ended", function () {});
      });

      let captions = [];

      player.src(
        [
          {
            src: this.data.video.url,
            type: "application/vnd.ms-sstr+xml",
          },
        ],
        captions
      );

      this.myPlayer = player;
      */
      //console.warn("video init");
      //console.warn(this.data);

      this.setupPlayer({
        type: "video",
        source: {
          title: "",
          hls: this.data.video.url,
        },

        config: {
          key: "a080bae6-deab-40c6-a7f8-8f01a7cdbadd",
          playback: {
            autoplay: false,
          },

          analytics: {
            key: "51d00662-bdac-4335-bb7d-6cf15b930c97",
            title: "[" + this.data.title + "] " + this.data.video.url,
            videoId: this.data.video.url,
          },
        },
      });
    },
    setupPlayer(item) {
      //console.warn(item);

      // eslint-disable-next-line no-undef
      player = new bitmovin.player.Player(
        document.getElementById(this.data.video.id),
        item.config
      );
      //UIFactory.buildDefaultUI(player);

      player.load(item.source).then(
        () => {
          this.player = player;
          // console.warn("Successfully loaded source");
          this.videoVisible = true;
        },
        () => {
          // console.warn("Error while loading source");
        }
      );
    },
    showVideo() {
      this.videoVisible = true;
    },
    videoClose() {
      if (player) {
        player.unload();
        this._player = null;
        this.player = null;
      }
      //this.player.unload();
      this.videoVisible = false;
    },

    cutterOnOff() {
      this.cutter = !this.cutter;
    },
  },
};

<template>
  <Waypoint>
    <div class="venue-participants">
      <div class="venue-participants-row-main">
        <div class="icon">
          <img src="@/assets/participants.png" />
        </div>
        <div class="companies-set">
          <div class="title-wrapper">
            <div class="title animated-title" v-html="data.title" />
            <div class="placeholder right">
              <img src="@/assets/pin.svg" class="pin-right" />
            </div>
          </div>
          <div
            class="companies-set-wrapper"
            v-for="(item, i) in data.rows.filter((x) => x.position == 'main')"
            :key="i"
          >
            <div class="companies-set-title" v-html="item.title" />
            <div class="companies-set-text" v-html="item.text" />
            <div class="companies-companies">
              <div v-for="item in item.brands" :key="item.logo">
                <img :src="item.logo" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        :class="{
          'companies-set-wrapper': true,
          left: item.position == 'left',
          right: item.position == 'right',
        }"
        v-for="(item, i) in data.rows.filter(
          (x) => x.position == 'left' || x.position == 'right'
        )"
        :key="i"
      >
        <div class="companies-set-title" v-html="item.title" />
        <div class="companies-set-text" v-html="item.text" />
        <div class="companies-companies">
          <div v-for="item in item.brands" :key="item.logo">
            <img :src="item.logo" />
          </div>
        </div>
      </div>
    </div>
  </Waypoint>
</template>

<script src="./VenueParticipants.js"></script>

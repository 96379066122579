export default {
  name: "ScenariosCombo",
  props: {
    scenarios: Array,
  },
  data() {
    return {
      state: false,
      stateDefault: true,
    };
  },
  created() {},
  methods: {
    getEnviroment() {
      return process.env.NODE_ENV;
    },

    onOpenClose() {
      this.state = !this.state;
      this.stateDefault = false;
    },

    getScenarios(side) {
      let items = [];
      const half = Math.ceil(this.scenarios.length / 2);

      if (side == "left") {
        for (let i = 0; i < half; i++) {
          items.push(this.scenarios[i]);
        }
      }

      if (side == "right") {
        for (let i = half; i <= this.scenarios.length - 1; i++) {
          items.push(this.scenarios[i]);
        }
      }

      return items;
    },
  },
};

import Gallery from "@/components/Gallery.vue";

export default {
  name: "ScenarioImages",
  props: {
    data: Array,
  },
  components: {
    Gallery,
  },
  data() {
    return {};
  },

  methods: {},
  created() {},
};

import { Waypoint } from "vue-waypoint";
import Gallery from "@/components/Gallery.vue";

export default {
  name: "ScenarioBenefits",
  props: {
    data: Object,
  },
  components: {
    Waypoint,
    Gallery,
  },
  created() {},
};

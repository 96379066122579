<template>
  <div>
    <Waypoint>
      <div class="venue-contact">
        <div class="venue-contact-wrapper">
          <div class="icon">
            <img alt="O-RAN logo" src="@/assets/contact.png" />
          </div>
          <div class="content">
            <div
              class="venue-contact-title animated-title"
              v-html="data.title"
            />
            <div class="venue-contact-description" v-html="data.description" />

            <div class="show-contacts-wrapper">
              <div class="show-contacts-btn" @click="onShowContacts">
                Show contacts
              </div>
            </div>

            <!--
            <div class="venue-contacts">
              <div
                class="contact-row"
                v-for="(item, i) in data.contacts"
                :key="i"
              >
                <div class="contact-name" v-html="item.name" />
                <div class="contact-email">
                  <a :href="'mailto:' + item.email">{{ item.email }}</a>
                </div>
              </div>
            </div>
            -->
          </div>
        </div>
      </div>
    </Waypoint>
    <modal
      v-if="showModal"
      @close="onCloseModal"
      :data="data"
      :content="modalType"
    ></modal>
  </div>
</template>

<script src="./VenueContact.js"></script>

<template>
  <Waypoint>
    <div class="scenarios-table">
      <div class="scenarios-table-wrapper">
        <div class="title-wrapper">
          <div class="title animated-title" v-html="data.title" />
        </div>

        <template v-if="data.table">
          <div class="table">
            <div class="row table-title">
              {{ data.table.title }}
            </div>
            <div class="row table-header">
              <div
                :class="{
                  id: j == 0,
                  name: j == 1,
                  lab: j == 2,
                  'description-short': j == 3,
                  learn: j == 4,
                  attachements: j == 5,
                }"
                :style="{}"
                v-for="(column, j) in data.table.header"
                :key="j"
                v-html="column"
              ></div>
            </div>
            <div
              :class="{ row: true, 'table-row': true, even: j % 2 == 0 }"
              v-for="(row, j) in data.table.rows"
              :key="j"
            >
              <div class="id" v-html="row.id"></div>
              <div class="name" v-html="row.name"></div>
              <div class="lab" v-html="row.lab"></div>
              <template v-if="openIndex == j">
                <div class="description">
                  <div v-html="row.descriptionLong"></div>
                  <div class="read-more" @click="onClose(j)">CLOSE</div>
                </div>
              </template>
              <template v-else>
                <div class="description">
                  <div v-html="row.descriptionShort"></div>
                  <div
                    class="read-more"
                    v-if="row.descriptionLong"
                    @click="onOpen(j)"
                  >
                    READ MORE
                  </div>
                </div>
              </template>
              <div class="attachements">
                <div
                  class="attachement"
                  v-for="(attachement, k) in row.attachements"
                  :key="k"
                >
                  <img
                    @click="onFileClick(attachement)"
                    :src="
                      'https://oranstorage.blob.core.windows.net/icons/' +
                      attachement.extension +
                      '.png'
                    "
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </Waypoint>
  <modal
    v-if="showModal"
    @close="onCloseModal"
    :data="videoData"
    :content="modalType"
  ></modal>
</template>

<script src="./ScenariosTable.js"></script>

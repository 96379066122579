export default {
  name: "Header",
  props: {
    msg: String,
  },
  data() {
    return {
      state: false,
      stateDefault: true,
    };
  },
  methods: {
    onOpenClose() {
      this.state = !this.state;
      this.stateDefault = false;
    },
    onLinkClick() {
      this.state = false;
      this.stateDefault = false;
    },
  },
};

<template>
  <div class="page home">
    <div class="venues-background"></div>
    <PageHeader />
    <div v-if="slideDetailShow" id="slide-detail">
      <img src="@/assets/close2.svg" id="close-modal-button" @click="onClick" />
      <div class="slide-detail-wrapper">
        <div class="slide-detail-content">
          <template v-if="$route.params.season == 'SPRING'">
            <p>
              Starting from 2022, O-RAN ALLIANCE switches to hosting two global
              PlugFests per year, to accommodate for ever increasing interest of
              the diverse O-RAN community to efficiently test and integrate
              their O-RAN solutions.
            </p>
            <p>
              O-RAN Global PlugFest Spring 2022 took place at 3 continents with
              50 participating companies or institutions, some of which
              participated at more than one venue.
            </p>
            <p>
              In North America, activities at the O-RAN ‘PoCFest’ hosted by AT&T
              and DISH focused on demonstrations of use cases within the O-RAN
              Use Cases Detailed Specification version 6.0 & 7.0, as well as
              Energy Saving / Energy Efficiency. The Proof-of-Concept approach
              also supported the evolution of testbeds to support the recently
              established next Generation Research Group within O-RAN.
            </p>
            <p>
              In Europe, activities at the O-RAN PlugFest Spring 2022 hosted by
              Telefonica focused on testing of open Xhaul transport solutions,
              mimicking realistic deployments to obtain meaningful insights
              about the solutions under test.
            </p>
            <p>
              In Asia, activities at the O-RAN PlugFest Spring 2022 hosted by
              Auray focused on verification of applicability of tests in lab
              environment for Open Fronthaul, E2E and security tests. Performed
              actions covered a dry run of the O-RAN Certification and Badging
              program and also included Open RAN Testing and Optimization for
              Industry IoT.
            </p>
          </template>
          <template v-else>
            <p>
              The O-RAN Global PlugFest Fall 2022 was successfully completed in
              6 venues across Asia, Europe, and North America, with 106
              participating companies.
            </p>
            <p>
              O-RAN ALLIANCE global PlugFests represent a major platform
              enabling efficient progress of the O-RAN ecosystem through
              well-organized testing and integration. O-RAN Global PlugFest Fall
              2022 is the second O-RAN PlugFest this year. In combination with
              the Spring PlugFest, the 2022 O-RAN Global PlugFests span across 9
              venues with 156 participants, of which many participated at more
              than one venue.
            </p>
            <p>
              “Testing and integration at O-RAN PlugFests show increasing
              maturity of O-RAN technology in all regions,” said Linsheng Liao,
              O-RAN TIFG Co-chair and Senior Project Manager of CMRI. “Comparing
              the PlugFest results year to year, we are pleased to see major
              progress made by the O-RAN ecosystem in conformance and
              interoperability tests, as well as more advanced end-to-end and
              network automation scenarios. O-RAN PlugFests greatly contribute
              to developing commercially available O-RAN networks.”
            </p>
          </template>
        </div>
      </div>
    </div>
    <div class="card-wrapper">
      <div class="col card" @click="onShowHideSlideDetail">
        <swiper
          :modules="modules"
          :slides-per-view="1"
          :space-between="80"
          @swiper="onSwiper"
          @slideChange="onSlideChange"
        >
          <swiper-slide>
            <div class="card-wrapper">
              <div class="card-content">
                <div class="card-image">
                  <template v-if="$route.params.season == 'SPRING'">
                    <img alt="News" src="@/assets/news_01.jpg" />
                  </template>
                  <template v-else>
                    <img alt="News" src="@/assets/news_02.png" />
                  </template>
                </div>

                <div class="card-title"></div>
                <div class="card-title-text">
                  <template v-if="$route.params.season == 'SPRING'">
                    O-RAN Global PlugFest Spring 2022 concluded at 3 continents
                    with 50 participants
                  </template>
                  <template v-else>
                    O-RAN ALLIANCE Advances Testing and Integration with
                    Successful O-RAN Global PlugFest Fall 2022</template
                  >
                </div>
                <div class="card-description">
                  <div>
                    <template v-if="$route.params.season == 'SPRING'">
                      O-RAN Global PlugFest Spring 2022 took place at 3
                      continents with 50 participating companies or
                      institutions, some of which participated at more than one
                      venue.
                    </template>
                    <template v-else>
                      The O-RAN Global PlugFest Fall 2022 was completed in 6
                      venues across Asia, Europe, and North America, with 106
                      participating companies.</template
                    >
                  </div>
                </div>
                <div class="card-read-more">
                  <template v-if="!slideDetailShow"
                    ><div>Read more</div></template
                  >
                  <template v-else><div>Close</div></template>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="col venues-items">
        <Venues
          :data="venues"
          :showSwitch="true"
          title="Select PlugFest venue to explore"
          :showScrenariosCombo="false"
        />
      </div>
    </div>

    <Participants
      v-if="alphabet"
      :data="alphabet"
      title="O-RAN Global PlugFest Spring/Fall 2022 Participants"
    />
    <Footer backgroundDirection="left" />
  </div>
</template>

<script src="./Home2022.js"></script>

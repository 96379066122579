<template>
  <Waypoint>
    <div class="page-header-scenario">
      <div class="row">
        <div class="col icon">
          <router-link :to="{ name: 'Home' }"
            ><img alt="O-RAN logo" src="@/assets/logo_plugfest_blue.svg"
          /></router-link>
        </div>
        <div class="col text">
          <div class="text-title" v-html="data.name" />
        </div>
        <div class="visits">{{ visits }}<br /><span>Visitors</span></div>
        <div
          :class="{
            col: true,
            menu: true,
            open: state,
            close: !state && !stateDefault,
          }"
        >
          <div class="icon-wrapper" v-on:click="onOpenClose">
            <img class="menu-icon" src="@/assets/menu-closed.svg" />
          </div>
          <div class="menu-venues">
            <div
              :class="{
                'venue-menu-item': true,
                'no-divider': i == venues.length - 1,
              }"
              v-for="(item, i) in venues"
              :key="i"
            >
              <template
                v-if="
                  (getEnviroment() == 'development' &&
                    item.development &&
                    item.active) ||
                  (getEnviroment() == 'production' &&
                    item.production &&
                    item.active)
                "
              >
                <router-link
                  :to="{ name: 'Venue-' + item.id }"
                  v-html="item.name"
                  v-on:click="onOpenClose"
                />
              </template>
              <template v-else>
                <div
                  class="menu-item-inactive"
                  v-html="item.name"
                  v-on:click="onOpenClose"
                />
              </template>
            </div>
            <!--
          <div class="venue-menu-item external">O-RAN</div>
          <div class="venue-menu-item external no-divider">
            Virtual Exhibition
          </div>
          --></div>
        </div>
      </div>
      <div>
        <ScenariosCombo :scenarios="scenarios" />
      </div>
    </div>
  </Waypoint>
</template>

<script src="./PageHeaderScenario.js"></script>

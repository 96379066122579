import { Waypoint } from "vue-waypoint";
import Gallery from "@/components/Gallery.vue";
import Table from "@/components/Table.vue";

export default {
  name: "ScenarioResults",
  props: {
    data: Object,
  },
  components: {
    Waypoint,
    Gallery,
    Table,
  },
  data() {
    return {};
  },
  methods: {},
  created() {},
};

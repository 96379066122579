import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";

// swiper bundle styles
import "swiper/swiper-bundle.min.css";

// swiper core styles
import "swiper/swiper.min.css";

// modules styles
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";

SwiperCore.use([Navigation, Pagination]);

export default {
  name: "Gallery",
  props: {
    data: Object,
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      isFullscreen: false,
      index: 0,
    };
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, Scrollbar, A11y],
    };
  },
  created() {},
  methods: {
    onFullscreen(index) {
      this.isFullscreen = true;
      document.body.classList.add("stop-scrolling");
      this.index = index;
    },
    onClose() {
      this.isFullscreen = false;
      document.body.classList.remove("stop-scrolling");
    },
    getSliderPerView() {
      let result = 0;
      if (this.data.length < 3) {
        result = this.data.length;
      } else {
        result = 3;
      }

      // console.warn(result);

      return result;
    },

    getFullscrenData() {
      /*
      console.warn({
        getFullscrenData: this.data,
      });
      */

      return this.data[this.index];
    },

    getGalleryWidth() {
      if (this.data.length == 1) {
        return {
          width: "50%",
        };
      } else if (this.data.length == 2) {
        return {
          width: "60%",
        };
      } else if (this.data.length == 3) {
        return {
          width: "80%",
        };
      } else {
        return {
          width: "100%",
        };
      }
    },
  },
};

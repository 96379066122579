export default {
  name: "PageHeaderVenue",
  props: {
    data: Array,
    visits: Number,
  },
  data() {
    return {
      state: false,
      stateDefault: true,
    };
  },
  methods: {
    getEnviroment() {
      return process.env.NODE_ENV;
    },
    onOpenClose() {
      this.state = !this.state;
      this.stateDefault = false;
    },
  },
  created() {},
};

<template>
  <Waypoint>
    <div class="scenario-benefits">
      <div class="title-wrapper">
        <div class="title animated-title" v-html="data.title" />
        <div class="placeholder right">
          <img src="@/assets/pin.svg" class="pin-right" />
        </div>
      </div>
      <div class="title-description" v-html="data.description"></div>
      <ul class="items-wrapper">
        <li
          class="item"
          v-for="(item, i) in data.items"
          :key="i"
          v-html="item"
        />
      </ul>
      <div class="image-wrapper" v-if="data.image">
        <div class="image">
          <img :src="data.image.url" />
        </div>
        <div
          class="title-description"
          v-if="data.image.subtext"
          v-html="data.image.subtext"
        ></div>
      </div>

      <template v-if="data.imageset && data.imageset.length > 0">
        <div class="images-wrapper">
          <Gallery :data="data.imageset" />
        </div>
      </template>
    </div>
  </Waypoint>
</template>

<script src="./ScenarioBenefits.js"></script>

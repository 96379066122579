<template>
  <div class="scenarios-combo-wrapper">
    <template v-if="scenarios.length > 0">
      <div class="col"></div>
      <div class="col-title">
        <div class="combo-title" v-on:click="onOpenClose">Tested Scenarios</div>
        <div
          :class="{
            'combo-icon': true,
            open: state,
            close: !state && !stateDefault,
          }"
        >
          <img
            src="@/assets/arrow-left.svg"
            v-on:click="onOpenClose"
            style="cursor: pointer"
          />
        </div>
      </div>
      <div class="col"></div>
      <div v-if="state" class="combo-content">
        <template v-if="scenarios.length > 1">
          <div class="col">
            <div
              class="item"
              v-for="(item, i) in getScenarios('left')"
              :key="i"
            >
              <template
                v-if="
                  (getEnviroment() == 'development' &&
                    item.development &&
                    item.active) ||
                  (getEnviroment() == 'production' &&
                    item.production &&
                    item.active)
                "
              >
                <router-link
                  :to="{ name: 'Scenario', params: { id: item.path } }"
                  v-html="item.name"
                  v-on:click="onOpenClose"
                ></router-link>
              </template>
              <template v-else>
                <span
                  class="not-active-scenario"
                  v-html="item.name"
                  v-on:click="onOpenClose"
                ></span>
              </template>
            </div>
          </div>
          <div class="col">
            <div
              class="item"
              v-for="(item, i) in getScenarios('right')"
              :key="i"
            >
              <template
                v-if="
                  (getEnviroment() == 'development' &&
                    item.development &&
                    item.active) ||
                  (getEnviroment() == 'production' &&
                    item.production &&
                    item.active)
                "
              >
                <router-link
                  :to="{ name: 'Scenario', params: { id: item.path } }"
                  v-html="item.name"
                  v-on:click="onOpenClose"
                ></router-link>
              </template>
              <template v-else>
                <span
                  class="not-active-scenario"
                  v-html="item.name"
                  v-on:click="onOpenClose"
                ></span>
              </template>
            </div>
          </div>
        </template>
        <template v-if="scenarios.length == 1">
          <div class="item" v-for="(item, i) in scenarios" :key="i">
            <template v-if="item.active">
              <router-link
                :to="{ name: 'Scenario', params: { id: item.path } }"
                v-html="item.name"
                v-on:click="onOpenClose"
              ></router-link>
            </template>
            <template v-if="!item.active">
              <span
                class="not-active-scenario"
                v-html="item.name"
                v-on:click="onOpenClose"
              ></span>
            </template>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script src="./ScenariosCombo.js"></script>

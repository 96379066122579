import { createRouter, createWebHistory } from "vue-router"; // createWebHashHistory createWebHistory
// import Home from "../views/Home.vue";
import Home2021 from "../views/Home2021.vue";
import Home2022 from "../views/Home2022.vue";
import Home2023 from "../views/Home2023.vue";
import Home2024 from "../views/Home2024.vue";
import Venue from "../views/Venue.vue";
import Venue2023 from "../views/Venue2023.vue";
import Venue2023SpringFall from "../views/Venue2023SpringFall.vue";
import Venue2024SpringFall from "../views/Venue2024SpringFall.vue";

import Scenario from "../views/Scenario.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    redirect: { path: "/2024/SPRING" },
  },
  {
    path: "/2024/:season",
    name: "Home2024",
    meta: {
      year: 2024,
    },
    component: Home2024,
  },
  {
    path: "/2023/:season",
    name: "Home2023",
    meta: {
      year: 2023,
    },
    component: Home2023,
  },
  {
    path: "/2022/:season",
    name: "Home2022",
    meta: {
      year: 2022,
    },
    component: Home2022,
  },
  {
    path: "/2021",
    name: "Home2021",
    meta: {
      year: 2021,
    },
    component: Home2021,
  },
  {
    path: "/2021/Joint_O-RAN_TIP_Linux_Foundation_PlugFest_&_PoCs",
    meta: {
      id: 1,
      type: "venue",
      year: 2021,
      season: "",
    },
    name: "Venue-1",
    component: Venue,
    alias: "/2021/Joint_O-RAN_TIP_Linux_Foundation_PlugFest_&_PoCs",
  },
  {
    path: "/2021/Joint_European_O-RAN_and_TIP_PlugFest",
    meta: {
      id: 2,
      type: "venue",
      year: 2021,
      season: "",
    },
    name: "Venue-2",
    component: Venue,
    alias: "/2021/Joint_European_O-RAN_and_TIP_PlugFest",
  },
  {
    path: "/2021/O-RAN_PlugFest_hosted_by_Skoltech",
    meta: {
      id: 3,
      type: "venue",
      year: 2021,
      season: "",
    },
    name: "Venue-3",
    component: Venue,
    alias: "/2021/O-RAN_PlugFest_hosted_by_Skoltech.html",
  },
  {
    path: "/2021/O-RAN_PlugFest_in_India",
    meta: {
      id: 4,
      type: "venue",
      year: 2021,
      season: "",
    },
    name: "Venue-4",
    component: Venue,
    alias: "/2021/O-RAN_PlugFest_in_India",
  },
  {
    path: "/2021/O-RAN_PlugFest_in_Auray_OTIC_and_Security_Lab",
    meta: {
      id: 5,
      type: "venue",
      year: 2021,
      season: "",
    },
    name: "Venue-5",
    component: Venue,
    alias: "/2021/O-RAN_PlugFest_in_Auray_OTIC_and_Security_Lab",
  },
  {
    path: "/2021/O-RAN_PlugFest_in_Republic_of_Korea",
    meta: {
      id: 6,
      type: "venue",
      year: 2021,
      season: "",
    },
    name: "Venue-6",
    component: Venue,
    alias: "/2021/O-RAN_PlugFest_in_Republic_of_Korea",
  },
  {
    path: "/2021/O-RAN_PlugFest_in_Japan",
    meta: {
      id: 7,
      type: "venue",
      year: 2021,
      season: "",
    },
    name: "Venue-7",
    component: Venue,
    alias: "/2021/O-RAN_PlugFest_in_Japan",
  },
  {
    path: "/2022/O-RAN_PoCFest_hosted_by_AT&T_and_DISH",
    meta: {
      id: 8,
      type: "venue",
      year: 2022,
      season: "Spring 2022",
    },
    name: "Venue-8",
    component: Venue,
    alias: "/2022/O-RAN_PoCFest_hosted_by_AT&T_and_DISH",
  },
  {
    path: "/2022/O-RAN_PlugFest_Spring_2022_hosted_by_Auray",
    meta: {
      id: 9,
      type: "venue",
      year: 2022,
      season: "Spring 2022",
    },
    name: "Venue-9",
    component: Venue,
    alias: "/2022/O-RAN_PlugFest_Spring_2022_hosted_by_Auray",
  },
  {
    path: "/2022/O-RAN_PlugFest_Spring_2022_hosted_by_Telefonica",
    meta: {
      id: 10,
      type: "venue",
      year: 2022,
      season: "Spring 2022",
    },
    name: "Venue-10",
    component: Venue,
    alias: "/2022/O-RAN_PlugFest_Spring_2022_hosted_by_Telefonica",
  },
  {
    path: "/2022/O-RAN_PlugFest_in_Japan_Fall_2022",
    meta: {
      id: 11,
      type: "venue",
      year: 2022,
      season: "Fall 2022",
    },
    name: "Venue-11",
    component: Venue2023,
    alias: "/2022/RAN_PlugFest_in_Japan_Fall_2022",
  },

  {
    path: "/2022/European_O-RAN_and_TIP_PlugFest_Fall_2022",
    meta: {
      id: 12,
      type: "venue",
      year: 2022,
      season: "Fall 2022",
    },
    name: "Venue-12",
    component: Venue2023,
    alias: "/2022/European_O-RAN_and_TIP_PlugFest_Fall_2022",
  },

  {
    path: "/2022/O-RAN_PlugFest_in_South_Korea_hosted_by_LG_Uplus",
    meta: {
      id: 13,
      type: "venue",
      year: 2022,
      season: "Fall 2022",
    },
    name: "Venue-13",
    component: Venue2023,
    alias: "/2022/O-RAN_PlugFest_in_South_Korea_hosted_by_LG_Uplus",
  },

  {
    path: "/2022/O-RAN_PlugFest_in_North_America_hosted_by_CableLabs",
    meta: {
      id: 14,
      type: "venue",
      year: 2022,
      season: "Fall 2022",
    },
    name: "Venue-14",
    component: Venue2023,
    alias: "/2022/O-RAN_PlugFest_in_North_America_hosted_by_CableLabs",
  },

  {
    path: "/2022/O-RAN_PlugFest_in_South_Korea_hosted_by_SK_Telecom_Fall_2022",
    meta: {
      id: 15,
      type: "venue",
      year: 2022,
      season: "Fall 2022",
    },
    name: "Venue-15",
    component: Venue2023,
    alias: "/2022/O-RAN_PlugFest_in_South_Korea_hosted_by_SK_Telecom_Fall_2022",
  },

  {
    path: "/2022/O-RAN_PocFest_in_North_America_hosted_by_UNH-IOL",
    meta: {
      id: 16,
      type: "venue",
      year: 2022,
      season: "Fall 2022",
    },
    name: "Venue-16",
    component: Venue2023,
    alias: "/2022/O-RAN_PocFest_in_North_America_hosted_by_UNH-IOL",
  },

  {
    path: "/2023/O-RAN_Global_PlugFest_hosted_by_Auray",
    meta: {
      id: 17,
      type: "venue",
      year: 2023,
      season: "Spring 2023",
      seasonId: "SPRING",
    },
    name: "Venue-17",
    component: Venue2023SpringFall,
    alias: "/2023/RAN_Global_PlugFest_hosted_by_Auray",
  },
  {
    // prettier-ignore
    path:
      "/2023/O-RAN_Global_PlugFest_hosted_by_Deutsche_Telekom_EANTC_EURECOM_Orange_Vodafone",
    meta: {
      id: 18,
      type: "venue",
      year: 2023,
      season: "Spring 2023",
      seasonId: "SPRING",
    },
    name: "Venue-18",
    component: Venue2023SpringFall,
    alias:
      "/2023/O-RAN_Global_PlugFest_hosted_by_Deutsche_Telekom_EANTC_EURECOM_Orange_Vodafone",
  },

  {
    path: "/2023/O-RAN_Global_PlugFest_hosted_by_University_of_New_Hampshire",
    meta: {
      id: 19,
      type: "venue",
      year: 2023,
      season: "Spring 2023",
      seasonId: "SPRING",
    },
    name: "Venue-19",
    component: Venue2023SpringFall,
    alias: "/2023/O-RAN_Global_PlugFest_hosted_by_University_of_New_Hampshire",
  },

  {
    path: "/2023/O-RAN_Global_PlugFest_hosted_by_Korea_Telecom",
    meta: {
      id: 20,
      type: "venue",
      year: 2023,
      season: "Spring 2023",
      seasonId: "SPRING",
    },
    name: "Venue-20",
    component: Venue2023SpringFall,
    alias: "/2023/O-RAN_Global_PlugFest_hosted_by_Korea_Telecom",
  },

  {
    path: "/2023/O-RAN_PlugFest_hosted_by_Japan_OTIC",
    meta: {
      id: 21,
      type: "venue",
      year: 2023,
      season: "Fall 2023",
      seasonId: "FALL",
    },
    name: "Venue-21",
    component: Venue2023SpringFall,
    alias: "/2023/O-RAN_PlugFest_hosted_by_Japan_OTIC",
  },

  {
    path: "/2023/O-RAN_PlugFest_hosted_by_LG_Uplus",
    meta: {
      id: 22,
      type: "venue",
      year: 2023,
      season: "Fall 2023",
      seasonId: "FALL",
    },
    name: "Venue-22",
    component: Venue2023SpringFall,
    alias: "/2023/O-RAN_PlugFest_hosted_by_LG_Uplus",
  },

  {
    path: "/2023/O-RAN_PlugFest_hosted_by_SK_Telecom",
    meta: {
      id: 23,
      type: "venue",
      year: 2023,
      season: "Fall 2023",
      seasonId: "FALL",
    },
    name: "Venue-23",
    component: Venue2023SpringFall,
    alias: "/2023/O-RAN_PlugFest_hosted_by_SK_Telecom",
  },

  {
    path: "/2023/O-RAN_PlugFest_hosted_by_Digital_Catapult",
    meta: {
      id: 24,
      type: "venue",
      year: 2023,
      season: "Fall 2023",
      seasonId: "FALL",
    },
    name: "Venue-24",
    component: Venue2023SpringFall,
    alias: "/2023/O-RAN_PlugFest_hosted_by_Digital_Catapult",
  },

  // prettier-ignore
  {
    path:
      "/2023/O-RAN_PlugFest_hosted_by_EURECOM_i14y_Lab_Orange_Telefonica_TIM_Vodafone",
    meta: {
      id: 25,
      type: "venue",
      year: 2023,
      season: "Fall 2023",
      seasonId: "FALL"
    },
    name: "Venue-25",
    component: Venue2023SpringFall,
    alias:
      "/2023/O-RAN_PlugFest_hosted_by_EURECOM_i14y_Lab_Orange_Telefonica_TIM_Vodafone",
  },

  {
    path: "/2023/O-RAN_PlugFest_hosted_by_CableLabs",
    meta: {
      id: 26,
      type: "venue",
      year: 2023,
      season: "Fall 2023",
      seasonId: "FALL",
    },
    name: "Venue-26",
    component: Venue2023SpringFall,
    alias: "/2023/O-RAN_PlugFest_hosted_by_CableLabs",
  },

  // prettier-ignore
  {
    path:
      "/2023/O-RAN_PlugFest_hosted_by_AT_AND_T_DISH_COSMOS_NIST_POWDER_UNH-IOL_Verizon_Lead_by_UNH-IOL",
    meta: {
      id: 27,
      type: "venue",
      year: 2023,
      season: "Fall 2023",
      seasonId: "FALL"
    },
    name: "Venue-27",
    component: Venue2023SpringFall,
    alias:
      "/2023/O-RAN_PlugFest_hosted_by_AT_AND_T_DISH_COSMOS_NIST_POWDER_UNH-IOL_Verizon_Lead_by_UNH-IOL",
  },

  // prettier-ignore
  {
      path:
        "/2024/O-RAN_PlugFest_hosted_by_Korea_Telecom",
      meta: {
        id: 28,
        type: "venue",
        year: 2024,
        season: "Spring 2024",
        seasonId: "SPRING"
      },
      name: "Venue-28",
      component: Venue2024SpringFall,
      alias:
        "/2024/O-RAN_PlugFest_hosted_by_Korea_Telecom",
    },

  // prettier-ignore
  {
    path:
      "/2024/O-RAN_PlugFest_hosted_by_Japan_OTIC",
    meta: {
      id: 29,
      type: "venue",
      year: 2024,
      season: "Spring 2024",
      seasonId: "SPRING"
    },
    name: "Venue-29",
    component: Venue2024SpringFall,
    alias:
      "/2024/O-RAN_PlugFest_hosted_by_Japan_OTIC",
  },

  // prettier-ignore
  {
      path:
        "/2024/O-RAN_PlugFest_hosted_by_Auray",
      meta: {
        id: 30,
        type: "venue",
        year: 2024,
        season: "Spring 2024",
        seasonId: "SPRING"
      },
      name: "Venue-30",
      component: Venue2024SpringFall,
      alias:
        "/2024/O-RAN_PlugFest_hosted_by_Auray",
    },

  // prettier-ignore
  {
    path:
      "/2024/O-RAN_PlugFest_hosted_by_Asia_and_Pacific_OTIC_in_Singapore_and_North_American_OTIC_in_the_Boston_Area",
    meta: {
      id: 31,
      type: "venue",
      year: 2024,
      season: "Spring 2024",
      seasonId: "SPRING"
    },
    name: "Venue-31",
    component: Venue2024SpringFall,
    alias:
      "/2024/O-RAN_PlugFest_hosted_by_Asia_and_Pacific_OTIC_in_Singapore_and_North_American_OTIC_in_the_Boston_Area",
  },

  // prettier-ignore
  {
      path:
        "/2024/O-RAN_PlugFest_hosted_by_Nakao_Lab",
      meta: {
        id: 32,
        type: "venue",
        year: 2024,
        season: "Spring 2024",
        seasonId: "SPRING"
      },
      name: "Venue-32",
      component: Venue2024SpringFall,
      alias:
        "/2024/O-RAN_PlugFest_hosted_by_Nakao_Lab",
    },

  // prettier-ignore
  {
    path:
      "/2024/O-RAN_PlugFest_hosted_by_LG_Uplus",
    meta: {
      id: 33,
      type: "venue",
      year: 2024,
      season: "Spring 2024",
      seasonId: "SPRING"
    },
    name: "Venue-33",
    component: Venue2024SpringFall,
    alias:
      "/2024/O-RAN_PlugFest_hosted_by_LG_Uplus",
  },

  // prettier-ignore
  {
      path:
        "/2024/O-RAN_PlugFest_hosted_by_Digital_Catapult",
      meta: {
        id: 34,
        type: "venue",
        year: 2024,
        season: "Spring 2024",
        seasonId: "SPRING"
      },
      name: "Venue-34",
      component: Venue2024SpringFall,
      alias:
        "/2024/O-RAN_PlugFest_hosted_by_Digital_Catapult",
    },

  // prettier-ignore
  {
    path:
      "/2024/O-RAN_PlugFest_hosted_by_EURECOM",
    meta: {
      id: 35,
      type: "venue",
      year: 2024,
      season: "Spring 2024",
      seasonId: "SPRING"
    },
    name: "Venue-35",
    component: Venue2024SpringFall,
    alias:
      "/2024/O-RAN_PlugFest_hosted_by_EURECOM",
  },

  // prettier-ignore
  {
      path:
        "/2024/Joint_North_America_O-RAN_PlugFest",
      meta: {
        id: 36,
        type: "venue",
        year: 2024,
        season: "Spring 2024",
        seasonId: "SPRING"
      },
      name: "Venue-36",
      component: Venue2024SpringFall,
      alias:
        "/2024/Joint_North_America_O-RAN_PlugFest",
    },

  // prettier-ignore
  {
    path:
      "/2024/O-RAN_PlugFest_hosted_by_Kyrio_OTIC",
    meta: {
      id: 37,
      type: "venue",
      year: 2024,
      season: "Spring 2024",
      seasonId: "SPRING"
    },
    name: "Venue-37",
    component: Venue2024SpringFall,
    alias:
      "/2024/O-RAN_PlugFest_hosted_by_Kyrio_OTIC",
  },

  {
    path: "/scenario/:id",
    name: "Scenario",
    component: Scenario,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

<template>
  <Waypoint>
    <div class="venue-introduction">
      <div class="venue-introduction-wrapper">
        <div class="title-wrapper">
          <div class="title animated-title" v-html="data.title" />
          <div class="placeholder right">
            <img src="@/assets/pin.svg" class="pin-right" />
          </div>
        </div>
        <div class="venue-introduction-description" v-html="data.description" />
        <div class="venue-introduction-text" v-html="data.text" />
      </div>
    </div>
  </Waypoint>
</template>

<script src="./VenueIntroduction.js"></script>

<template>
  <div class="page home">
    <div class="venues-background"></div>
    <PageHeader />
    <div v-if="slideDetailShow" id="slide-detail">
      <img src="@/assets/close2.svg" id="close-modal-button" @click="onClick" />
      <div class="slide-detail-wrapper">
        <div class="slide-detail-content">
          <template v-if="$route.params.season == 'SPRING'">
            <p>
              The O-RAN Global PlugFest Spring 2023 was conducted from April to
              June 2 in ten labs across Asia, Europe and North America, with 60
              participating companies or institutions. The tests provided
              participants validation of O-RAN equipment conformance,
              performance and interoperability; tackled issues and overcome
              hurdles to wider technology adoption; and facilitated exploration
              of new features, scenarios and applications.
            </p>
            <p>
              “The sheer scale of participation in O-RAN PlugFests is testament
              to industry support and momentum of deployment,” said Alex Jinsung
              Choi, Chair of the Board of O-RAN ALLIANCE and SVP Group
              Technology, Deutsche Telekom. “In the Spring 2023 PlugFest, we
              expanded the ecosystem by testing products from new vendors.
              Participants validated the security of end-to-end O-RAN
              architecture and demonstrated the power of the RIC to support
              critical use cases such as quality of service and energy
              efficiency.”
            </p>
          </template>
          <template v-if="$route.params.season == 'FALL'">
            <p>
              The O-RAN Global PlugFest Fall 2023 carried out critical testing
              and validation of O-RAN technologies to support and encourage
              ongoing adoption by the industry. The tests provided participants
              validation of O-RAN equipment conformance, performance and
              interoperability; tackled issues to overcome hurdles to wider
              technology adoption; and facilitated exploration of new features,
              scenarios and applications. Of note, two labs conducted field
              trials of O-RAN equipment, one in a rural environment and the
              other in an urban one.
            </p>
            <p>
              “The continued level of industry participation in the Global
              PlugFest demonstrates commitment and progress in advancing O-RAN,”
              said Alex Jinsung Choi, Chair of the Board of O-RAN ALLIANCE and
              SVP Group Technology, Deutsche Telekom. “In the Fall 2023
              PlugFest, we expanded scope of testing beyond lab-based
              conformance and interoperability of equipment. Security validation
              continued to be a priority throughout the PlugFest. Several venues
              hosted demonstrations of RIC for energy savings, enabling
              attainment of sustainability objectives. And participants applied
              AI/ML for intelligent automation of network operation.”
            </p></template
          >
        </div>
      </div>
    </div>
    <div class="card-wrapper">
      <div class="col card" @click="onShowHideSlideDetail">
        <swiper
          :modules="modules"
          :slides-per-view="1"
          :space-between="80"
          @swiper="onSwiper"
          @slideChange="onSlideChange"
        >
          <swiper-slide>
            <div class="card-wrapper">
              <div class="card-content">
                <div class="card-image">
                  <template v-if="$route.params.season == 'SPRING'">
                    <img alt="News" src="@/assets/news_01.jpg" />
                  </template>
                  <template v-if="$route.params.season == 'FALL'">
                    <img alt="News" src="@/assets/news_02.png" />
                  </template>
                </div>

                <div class="card-title"></div>
                <div class="card-title-text">
                  <template v-if="$route.params.season == 'SPRING'">
                    O-RAN Global PlugFest Spring 2023 was conducted in 10 labs
                    across the world.
                  </template>
                  <template v-if="$route.params.season == 'FALL'"
                    >O-RAN Global PlugFest Fall 2023 was co-hosted by 24 major
                    operators, OTIC and independent institutions.</template
                  >
                </div>
                <div class="card-description">
                  <div>
                    <template v-if="$route.params.season == 'SPRING'">
                      The O-RAN Global PlugFest Spring 2023 was conducted from
                      April to June 2 in ten labs across Asia, Europe and North
                      America, with 60 participating companies or institutions.
                    </template>
                    <template v-if="$route.params.season == 'FALL'"
                      >O-RAN Global PlugFest Fall 2023 was conducted from
                      September to November 2023 in 16 labs across Asia, Europe
                      and North America, with 52 participating companies or
                      institutions.</template
                    >
                  </div>
                </div>
                <div class="card-read-more">
                  <template v-if="!slideDetailShow"
                    ><div>Read more</div></template
                  >
                  <template v-else><div>Close</div></template>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="col venues-items">
        <Venues
          :data="venues"
          :showSwitch="true"
          title="Select PlugFest venue to explore"
          :showScrenariosCombo="false"
        />
      </div>
    </div>

    <Participants
      v-if="alphabet"
      :data="alphabet"
      title="O-RAN Global PlugFest Spring/Fall 2023 Participants"
    />
    <Footer backgroundDirection="left" />
  </div>
</template>

<script src="./Home2023.js"></script>

import { Waypoint } from "vue-waypoint";

export default {
  name: "Participants",
  props: {
    data: Object,
    title: String,
  },
  components: {
    Waypoint,
  },
  data() {
    return {
      state: false,
    };
  },
  created() {},
  methods: {
    onOpenClose() {
      this.state = !this.state;
    },

    scrollMeTo(refName) {
      if (!this.state) {
        this.state = true;

        setTimeout(() => {
          this.scrollMeTo(refName);
        }, 300);
      }

      var element = this.$refs[refName];

      if (element) {
        /*
        console.warn({
          refName,
          element,
          refs: this.$refs,
        });*/

        element[0].scrollIntoView({
          behavior: "smooth",
          inline: "nearest",
        });

        setTimeout(() => {
          element[0].scrollIntoView({
            behavior: "smooth",
            inline: "nearest",
          });
        }, 300);
      }
    },
  },
};

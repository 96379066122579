<template>
  <div
    :class="{
      separator: true,
      left: backgroundDirection == 'left',
      right: backgroundDirection == 'right',
    }"
  ></div>
</template>

<script src="./Separator.js"></script>

<template>
  <div class="page home">
    <div class="venues-background"></div>
    <PageHeader />
    <Venues
      :data="venues"
      :showSwitch="true"
      title="Select PlugFest venue to explore"
      :showScrenariosCombo="false"
    />
    <Participants
      v-if="alphabet"
      :data="alphabet"
      title="O-RAN Global PlugFest 2021 Participants"
    />
    <Footer backgroundDirection="left" />
  </div>
</template>

<script src="./Home2021.js"></script>

<template>
  <Waypoint>
    <div class="scenario-results">
      <div class="title-wrapper">
        <div class="placeholder left">
          <img src="@/assets/pin.svg" class="pin-left" />
        </div>
        <div class="title animated-title" v-html="data.title" />
      </div>
      <div class="description" v-html="data.description" />

      <div class="table-wrapper" v-if="data.table">
        <Table :data="data.table" />
      </div>

      <template v-if="data.imageset && data.imageset.length > 0">
        <div class="images-wrapper">
          <Gallery :data="data.imageset" />
        </div>
      </template>

      <template v-if="data.items && data.items.length > 0">
        <div
          class="result-item-wrapper"
          v-for="(item, i) in data.items"
          :key="i"
          :class="{
            'direction-left': item.direction == 'left',
            'direction-right': item.direction == 'right',
          }"
        >
          <div
            class="result-item-title"
            v-html="item.title"
            v-if="item.title"
          />
          <div
            class="result-item-subtitle"
            v-html="item.subtitle"
            v-if="item.subtitle"
          />

          <div
            class="result-item-description"
            v-if="item.description"
            v-html="item.description"
          />
          <div class="device-attributes">
            <template v-if="item.attributes">
              <div
                class="device-attribute"
                v-for="(attribute, j) in item.attributes"
                :key="j"
              >
                <div class="attribute-name">
                  <span
                    v-if="attribute.name"
                    v-html="attribute.name + (attribute.value != '' ? ':' : '')"
                  ></span>
                </div>
                <div class="attribute-value" v-html="attribute.value" />
              </div>
            </template>
            <template v-if="item.list">
              <ul>
                <li
                  class="device-list-item"
                  v-for="(attribute, j) in item.list"
                  :key="j"
                  v-html="attribute"
                ></li>
              </ul>
            </template>
          </div>
          <div
            class="result-item-image"
            v-for="(image, j) in item.images"
            :key="j"
          >
            <div
              class="result-item-image-description"
              v-html="image.description"
            />
            <img :src="image.url" />
            <div class="result-item-image-subtext" v-html="image.subtext" />
          </div>

          <Table :data="item.table" v-if="item.table" />

          <template v-if="item.tables">
            <Table
              v-for="(table, j) in item.tables"
              :key="j"
              :data="table"
            ></Table>
          </template>

          <template v-if="item.imageset && item.imageset.length > 0">
            <div class="images-wrapper">
              <Gallery :data="item.imageset" />
            </div>
          </template>

          <template v-if="item.items">
            <template v-for="(itemitem, k) in item.items" :key="k">
              <template v-if="itemitem.tables">
                <Table
                  v-for="(_table, l) in itemitem.tables"
                  :key="l"
                  :data="_table"
                ></Table>
              </template>

              <template v-if="itemitem.images">
                <div
                  class="result-item-image itemitem"
                  v-for="(_image, l) in itemitem.images"
                  :key="l"
                >
                  <div
                    class="result-item-image-description"
                    v-html="_image.description"
                  />
                  <img :src="_image.url" />
                  <div
                    class="result-item-image-subtext"
                    v-html="_image.subtext"
                  />
                </div>
              </template>
            </template>
          </template>
        </div>
      </template>
    </div>
  </Waypoint>
</template>

<script src="./ScenarioResults.js"></script>

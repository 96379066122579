import Setting from "../../setting.js";
import PageHeader from "@/components/PageHeaderVenue.vue";
import VenueTitle from "@/components/VenueTitle.vue";
import VenueDescription from "@/components/VenueDescription.vue";
import VenueIntroduction from "@/components/VenueIntroduction.vue";
import Separator from "@/components/Separator.vue";
import VenueImages from "@/components/VenueImages.vue";
import VenueLabs from "@/components/VenueLabs.vue";
import VenueParticipants from "@/components/VenueParticipants.vue";
import Video from "@/components/Video.vue";
import Pdf from "@/components/Pdf.vue";
import VenueTip from "@/components/VenueTip.vue";
import VenueContact from "@/components/VenueContact.vue";
import ScenariosTable from "@/components/ScenariosTable.vue";
import Venues from "@/components/Venues.vue";

import Footer from "@/components/Footer.vue";

export default {
  name: "Venue",
  components: {
    PageHeader,
    VenueTitle,
    VenueDescription,
    VenueIntroduction,
    Separator,
    VenueImages,
    VenueLabs,
    VenueParticipants,
    Video,
    Pdf,
    VenueTip,
    VenueContact,
    ScenariosTable,
    Venues,

    Footer,
  },
  data() {
    return {
      venues: null,
      venue: null,
      scenarios: null,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      /*
      console.warn({
        route: this.$route,
        router: this.$router.getRoutes().find((x) => x.path == this.$route.path)
          .meta.id,
      });
      */

      this.axios
        .get(Setting[process.env.NODE_ENV].API + "Venue/GetAll", {
          params: {
            year: this.$route.meta.year,
          },
        })

        .then((response) => {
          this.venues = response.data;
        });

      this.axios
        .get(Setting[process.env.NODE_ENV].API + "Venue/GetOne", {
          params: {
            id: this.$router.getRoutes().find((x) => x.path == this.$route.path)
              .meta.id, //this.$route.params.id,
          },
        })
        .then((response) => {
          this.venue = response.data;
        });

      this.axios
        .get(Setting[process.env.NODE_ENV].API + "Scenario/GetAllByVenue", {
          params: {
            id: this.$router.getRoutes().find((x) => x.path == this.$route.path)
              .meta.id, // this.$route.params.id,
          },
        })
        .then((response) => {
          this.scenarios = response.data;
        });
    },
  },
  watch: {
    $route(to, from) {
      /*
      console.warn({
        to,
        from,
      });
      */

      if (from.meta.type == "venue" && to.meta.type == "venue") {
        this.getData();
      }

      if (to.name == "Scenario") {
        //
      } else {
        // this.getData();
      }

      //}
    },
  },
};

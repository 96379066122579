<template>
  <transition name="modal">
    <div
      :class="{ 'modal-mask': true, 'contacts-modal': content == 'CONTACTS' }"
      @click="onClick"
      id="modal-mask"
    >
      <div class="modal-wrapper" id="modal-wrapper">
        <div class="modal-container" id="modal-container">
          <div class="modal-header">
            <slot name="header">
              <img
                src="@/assets/close2.svg"
                id="close-modal-button"
                @click="onClick"
              />
            </slot>
          </div>

          <div class="modal-body" id="modal-body">
            <slot name="body">
              <template v-if="content == 'VIDEO'"
                ><Video :data="data"
              /></template>
              <template v-if="content == 'PDF'"><Pdf :data="data" /></template>
              <template v-if="content == 'CONTACTS'">
                <div class="venue-contacts">
                  <vue-recaptcha
                    v-if="!isRecaptchaVerified"
                    v-show="showRecaptcha"
                    sitekey="6LeVxyIpAAAAAHD-pvCYVjxf01CHBUcD9SEC-twy"
                    size="normal"
                    theme="light"
                    hl="en"
                    :loading-timeout="loadingTimeout"
                    @verify="recaptchaVerified"
                    @expire="recaptchaExpired"
                    @fail="recaptchaFailed"
                    @error="recaptchaError"
                    ref="vueRecaptcha"
                  >
                  </vue-recaptcha>
                  <div v-if="isRecaptchaVerified">
                    <div
                      class="contact-row"
                      v-for="(item, i) in data.contacts"
                      :key="i"
                    >
                      <div class="contact-name" v-html="item.name" />
                      <div class="contact-email">
                        <a :href="'mailto:' + item.email">{{ item.email }}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </slot>
          </div>

          <div class="modal-footer">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script src="./Modal.js"></script>

// @ is an alias to /src
import Setting from "../../setting.js";
import PageHeader from "@/components/PageHeaderHome.vue";
import Venues from "@/components/Venues.vue";
import Separator from "@/components/Separator.vue";
import Participants from "@/components/Participants.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Home2021",
  components: {
    PageHeader,
    Venues,
    Separator,
    Participants,
    Footer,
  },
  data() {
    return {
      venues: null,
      alphabet: null,
    };
  },
  created() {
    this.axios
      .get(Setting[process.env.NODE_ENV].API + "Venue/GetAll", {
        params: {
          year: 2021,
        },
      })
      .then((response) => {
        this.venues = response.data;
      });

    this.axios
      .get(Setting[process.env.NODE_ENV].API + "Venue/GetBands", {
        params: {
          year: 2021,
        },
      })
      .then((response) => {
        this.alphabet = response.data;
      });
  },
};

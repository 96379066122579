<template>
  <div class="scenario-pager">
    <div class="pager-line"></div>
    <div class="pager-wrapper">
      <div class="col left">
        <router-link
          v-if="getPrev() != null"
          :to="{ name: 'Scenario', params: { id: getPrev() } }"
          >PREVIOUS SCENARIO</router-link
        >
      </div>
      <router-link :to="{ name: 'Home' }" class="col middle">
        <img alt="O-RAN logo" src="@/assets/logo_plugfest.svg" />

        <div>O-RAN Global PlugFest 2021</div>
      </router-link>
      <div class="col right">
        <router-link
          v-if="getNext() != null"
          :to="{ name: 'Scenario', params: { id: getNext() } }"
          >NEXT SCENARIO</router-link
        >
      </div>
    </div>
    <div>
      <ScenariosCombo :scenarios="scenarios" />
    </div>
  </div>
</template>

<script src="./ScenarioPager.js"></script>

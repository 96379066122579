<template>
  <Waypoint>
    <div class="venue-title">
      <div
        class="venue-title-wrapper"
        :style="{ 'background-image': 'url(' + background + ')' }"
      >
        <div class="venue-title-text animated-left-right" v-html="data" />
      </div>
    </div>
  </Waypoint>
</template>

<script src="./VenueTitle.js"></script>

import { Waypoint } from "vue-waypoint";

export default {
  name: "ScenarioName",
  props: {
    data: String,
  },
  components: {
    Waypoint,
  },
  created() {},
};

<template>
  <div class="participants">
    <Waypoint>
      <div class="participants-title-wrapper">
        <div class="placeholder left">
          <img src="@/assets/pin.svg" class="pin-left" />
        </div>
        <div class="title">
          Select PlugFest participants to learn about their engagement
        </div>
        <div class="placeholder right"></div>
      </div>
    </Waypoint>
    <Waypoint>
      <div class="participants-wrapper">
        <div class="side-space"></div>

        <div class="participants-content">
          <div class="participants-header" v-on:click="onOpenClose">
            {{ title }}
          </div>
          <div class="participants-list">
            <div class="letter" v-for="(item, key) in data" :key="key">
              <a @click="scrollMeTo(key + '_anchor')"> {{ key }} </a>
            </div>
          </div>
        </div>

        <div class="side-space"></div>
      </div>
    </Waypoint>
    <div class="participants-wrapper">
      <div class="side-space"></div>
      <div v-if="state" class="participants-open-content">
        <div
          class="letter"
          v-for="(item, key) in data"
          :key="key"
          :ref="key + '_anchor'"
        >
          <div class="letter-start">
            {{ key }}
          </div>
          <div class="letter-content">
            <div class="brands">
              <div class="brand" v-for="(brand, i) in item" :key="i">
                <div class="brand-info">
                  <div class="brand-logo">
                    <img :src="brand.logo" />
                  </div>
                  <div class="brand-name">{{ brand.name }}</div>
                </div>
                <div class="brand-venues">
                  <div
                    class="venue"
                    v-for="(venue, y) in brand.venues"
                    :key="y"
                  >
                    <div class="venue-name">
                      <router-link :to="{ name: 'Venue-' + venue.id }"
                        >{{ venue.name.replace('<br />','')}}</router-link
                      >
                    </div>
                    <div class="venue-scenarios">
                      <div
                        class="scenario"
                        v-for="(scenario, z) in venue.scenariosList"
                        :key="z"
                      >
                        <router-link
                          :to="{
                            name: 'Scenario',
                            params: { id: scenario.path },
                          }"
                          v-html="scenario.name"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="side-space"></div>
    </div>
  </div>
</template>

<script src="./Participants.js"></script>

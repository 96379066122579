<template>
  <div
    id="footer"
    :class="{
      left: backgroundDirection == 'left',
      right: backgroundDirection == 'right',
    }"
  >
    <div class="footer-content">
      <div class="logo">
        <a target="_blank" href="https://www.o-ran.org/">
          <img alt="O-RAN logo" src="@/assets/logo-footer.svg" />
        </a>
      </div>
      <div class="text">
        © 2024 O-RAN Alliance e.V. All rights reserved.
        <a
          target="_blank"
          href="https://www.o-ran.org/legal"
          :style="{ color: '#fff' }"
          >Legal Matters</a
        >
      </div>
    </div>
  </div>
</template>

<script src="./Footer.js"></script>

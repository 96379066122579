<template>
  <Waypoint>
    <div class="venue-tip">
      <div class="venue-tip-wrapper">
        <div class="venue-tip-background" />
        <div class="venue-tip-title animated-title" v-html="data.title" />
        <div class="venue-tip-text" v-html="data.text" />
      </div>
    </div>
  </Waypoint>
</template>

<script src="./VenueTip.js"></script>

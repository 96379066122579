// @ is an alias to /src
import Setting from "../../setting.js";
import PageHeader from "@/components/PageHeaderHome.vue";
import Venues from "@/components/Venues.vue";
import Separator from "@/components/Separator.vue";
import Participants from "@/components/Participants.vue";
import Footer from "@/components/Footer.vue";

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";

SwiperCore.use([Navigation, Pagination]);

export default {
  name: "Home2022",
  components: {
    PageHeader,
    Venues,
    Separator,
    Participants,
    Footer,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      venues: null,
      alphabet: null,
      slideDetailShow: false,
    };
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, Scrollbar, A11y], // Pagination           :pagination="{ clickable: true }"
    };
  },
  created() {
    this.axios
      .get(Setting[process.env.NODE_ENV].API + "Venue/GetAll", {
        params: {
          year: 2022,
        },
      })
      .then((response) => {
        if (this.$route.params.season == "FALL") {
          this.venues = response.data.filter(
            (x) =>
              x.id == 11 ||
              x.id == 12 ||
              x.id == 13 ||
              x.id == 14 ||
              x.id == 15 ||
              x.id == 16
          );
        } else {
          this.venues = response.data.filter(
            (x) => x.id == 8 || x.id == 9 || x.id == 10
          );
        }
      });

    this.axios
      .get(Setting[process.env.NODE_ENV].API + "Venue/GetBands", {
        params: {
          year: 2022,
        },
      })
      .then((response) => {
        this.alphabet = response.data;
      });
  },
  methods: {
    onShowHideSlideDetail() {
      if (!this.slideDetailShow) {
        this.slideDetailShow = true;
      } else {
        this.slideDetailShow = false;
      }
    },
    onClick() {
      this.slideDetailShow = false;
    },
  },
};

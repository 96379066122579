<template>
  <div class="venue-description">
    <ScenariosCombo v-if="scenarios.length > 0" :scenarios="scenarios" />
    <div class="venue-description-wrapper">
      <div class="venue-description-text" v-html="data" />
    </div>
  </div>
</template>

<script src="./VenueDescription.js"></script>

<template>
  <div class="header">
    <div class="header-wrapper">
      <div class="header-logo">
        <a target="_blank" href="https://www.o-ran.org/">
          <img alt="O-RAN logo" src="@/assets/logo.svg" />
        </a>
      </div>
      <div class="header-links">
        <a
          href="https://www.o-ran.org/who-we-are"
          target="_blank"
          class="header-link"
          >Who we are</a
        >
        <a
          href="https://www.o-ran.org/what-we-do"
          target="_blank"
          class="header-link"
          >What we do</a
        >
        <a
          href="https://www.o-ran.org/o-ran-ecosystem"
          target="_blank"
          class="header-link"
          >O-RAN Ecosystem</a
        >
        <a href="https://www.o-ran.org/news" target="_blank" class="header-link"
          >News & Events</a
        >
        <!--
        <a
          href="https://oranalliance.atlassian.net/wiki/spaces/PAT/overview"
          target="_blank"
          class="header-link"
          >O-RAN Portal</a
        >
        -->
      </div>
    </div>

    <div
      :class="{
        'main-menu': true,
        open: state,
        close: !state && !stateDefault,
      }"
    >
      <div class="main-menu-icon-wrapper" v-on:click="onOpenClose">
        <img class="menu-icon" src="@/assets/menu-main-closed.svg" />
      </div>
      <div class="main-menu-items">
        <a
          href="https://www.o-ran.org/about"
          target="_blank"
          class="header-link"
          v-on:click="onLinkClick"
          >Who we are</a
        >
        <a
          href="https://www.o-ran.org"
          target="_blank"
          class="header-link"
          v-on:click="onLinkClick"
          >What we do</a
        >
        <a
          href="https://www.o-ran.org/ecosystem"
          target="_blank"
          class="header-link"
          v-on:click="onLinkClick"
          >O-RAN Ecosystem</a
        >
        <a
          href="https://www.o-ran.org/news"
          target="_blank"
          class="header-link"
          v-on:click="onLinkClick"
          >News & Events</a
        >
      </div>
    </div>
  </div>
</template>

<script src="./Header.js"></script>

export default {
  name: "PageHeaderHome",
  props: {
    msg: String,
  },
  data() {
    return {
      year: null,
      currentYear: 2024,
      // HACK
      // season: "SPRING",
      season: "FALL",
      yearSelectorShow: false,
      yearsDropdownShow: false,
    };
  },
  methods: {
    onYearChange(event) {
      this.year = event;
    },
    onYearSelect(year) {
      this.year = year;
      this.yearsDropdownShow = false;

      if (this.year == 2020) {
        window.open("/2020", "_blank");
      } else {
        if (!this.season) {
          // HACK
          // this.season = "SPRING";
          this.season = "SPRING";
        }

        if (year == 2024) {
          this.season = "SPRING";
        }

        this.$router.push({
          name: "Home" + this.year,
          params: { season: this.season },
        });
      }
    },
    // HACK
    //onSeasonSelect() {

    // }
    onSeasonSelect(season) {
      this.season = season;

      window.location = "/" + this.$route.meta.year + "/" + this.season;
    },
    onYearDropdownVisibility() {
      if (!this.yearsDropdownShow) {
        this.yearsDropdownShow = true;
      } else {
        this.yearsDropdownShow = false;
      }
    },
  },
  created() {
    this.currentYear = this.$route.meta.year;
    this.season = this.$route.params.season;

    if (this.currentYear == "") {
      this.currentYear = 2024;
    }

    if (this.currentYear == 2024 && !this.season) {
      // HACK
      // this.season = "SPRING";
      this.season = "SPRING";
    }

    /*
    console.warn({
      currentYear: this.currentYear,
    });
    */
  },
};

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import VueScrollPicker from "vue-scroll-picker";
import "vue-scroll-picker/lib/style.css";

import axios from "axios";
import VueAxios from "vue-axios";

import VueEasyLightbox from "vue-easy-lightbox";

createApp(App)
  .use(store)
  .use(router)
  .use(VueAxios, axios)
  .use(VueEasyLightbox)
  .use(VueScrollPicker)
  .mount("#app");

<template>
  <div class="page scenario">
    <PageHeader
      v-if="venues && scenario && scenarios && scenario.parent"
      :data="scenario.parent"
      :scenarios="scenarios"
      :venues="venues"
      :visits="scenario.visits"
    />
    <ScenarioName v-if="scenario && scenario.name" :data="scenario.name" />
    <Separator backgroundDirection="left" />
    <Scenario3D v-if="scenario && scenario.plan" :data="scenario.plan" />
    <Separator backgroundDirection="left" />
    <ScenarioParticipants
      v-if="scenario && scenario.participants"
      :data="scenario.participants"
    />
    <Separator
      backgroundDirection="right"
      v-if="scenario && scenario.diagram"
    />
    <ScenarioDiagram
      v-if="scenario && scenario.diagram"
      :data="scenario.diagram"
    />
    <Separator
      backgroundDirection="right"
      v-if="scenario && scenario.devices"
    />
    <ScenarioDevice
      v-if="scenario && scenario.devices"
      :data="scenario.devices"
    />
    <Separator
      backgroundDirection="left"
      v-if="scenario && scenario.introduction"
    />
    <ScenarioIntroduction
      v-if="scenario && scenario.introduction"
      :data="scenario.introduction"
    />
    <Separator backgroundDirection="left" v-if="scenario && scenario.summary" />
    <ScenarioResults
      v-if="scenario && scenario.summary"
      :data="scenario.summary"
    />

    <template v-if="scenario && scenario.videos">
      <template v-for="(item, i) in scenario.videos" :key="i">
        <Separator backgroundDirection="left" />
        <Video :data="item" :isOdd="i % 2 == 0" />
      </template>
    </template>
    <template v-if="scenario && scenario.pdfs">
      <template v-for="(item, i) in scenario.pdfs" :key="i">
        <Separator backgroundDirection="left" />
        <Pdf :data="item" :isOdd="i % 2 == 0" />
      </template>
    </template>
    <Separator
      backgroundDirection="left"
      v-if="scenario && scenario.benefits"
    />
    <ScenarioBenefits
      v-if="scenario && scenario.benefits"
      :data="scenario.benefits"
    />
    <Separator
      backgroundDirection="left"
      v-if="scenario && scenario.imageset && scenario.imageset.length > 0"
    />
    <ScenarioImages
      v-if="scenario && scenario.imageset"
      :data="scenario.imageset"
    />
    <Separator backgroundDirection="left" />
    <ScenarioPager v-if="scenarios" :scenarios="scenarios" />
    <Footer backgroundDirection="right" />
  </div>
</template>

<script src="./Scenario.js"></script>

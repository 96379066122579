<template>
  <div class="table-wrapper">
    <div class="table-title" v-html="data.title" v-if="data.title"></div>
    <div class="table">
      <div class="row table-header">
        <div
          :class="{ 'table-column': true, first: j == 0 }"
          :style="{
            'max-width': 100 / data.header.length + '%',
            width: 100 / data.header.length + '%',
          }"
          v-for="(column, j) in data.header"
          :key="j"
          v-html="column"
        ></div>
      </div>
      <div class="row" v-for="(row, j) in data.rows" :key="j">
        <div
          :class="{ 'table-column': true, first: j == 0 }"
          :style="{
            'max-width': 100 / row.row.length + '%',
            width: 100 / row.row.length + '%',
          }"
          v-for="(column, j) in row.row"
          :key="j"
          v-html="column"
        ></div>
      </div>
    </div>
    <div class="footer" v-html="data.footer"></div>
  </div>
</template>

<script src="./Table.js"></script>

<template v-if="data">
  <Waypoint>
    <div class="venue-labs">
      <div class="venue-labs-wrapper">
        <div class="title-wrapper">
          <div class="placeholder left">
            <img src="@/assets/pin.svg" class="pin-left" />
          </div>
          <div class="title animated-title" v-html="data.title" />
        </div>
        <div class="description" v-html="data.description" />
        <div class="venue-labs-companies">
          <div class="venue-labs-companies-wrapper">
            <div v-for="item in data.brands" :key="item.logo">
              <img :src="item.logo" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Waypoint>
</template>

<script src="./VenueLabs.js"></script>

import Setting from "../../setting.js";
import PageHeader from "@/components/PageHeaderScenario.vue";
import ScenarioName from "@/components/ScenarioName.vue";
import Separator from "@/components/Separator.vue";
import Scenario3D from "@/components/Scenario3D.vue";
import ScenarioParticipants from "@/components/ScenarioParticipants.vue";
import ScenarioDiagram from "@/components/ScenarioDiagram.vue";
import ScenarioDevice from "@/components/ScenarioDevice.vue";
import ScenarioIntroduction from "@/components/ScenarioIntroduction.vue";
import ScenarioResults from "@/components/ScenarioResults.vue";
import ScenarioBenefits from "@/components/ScenarioBenefits.vue";
import Video from "@/components/Video.vue";
import Pdf from "@/components/Pdf.vue";
import Footer from "@/components/Footer.vue";
import ScenarioImages from "@/components/ScenarioImages.vue";
import ScenarioPager from "@/components/ScenarioPager.vue";

export default {
  name: "Scenario",
  components: {
    PageHeader,
    ScenarioName,
    Separator,
    Scenario3D,
    ScenarioParticipants,
    ScenarioDiagram,
    ScenarioDevice,
    ScenarioIntroduction,
    ScenarioResults,
    ScenarioBenefits,
    Video,
    ScenarioImages,
    Footer,
    ScenarioPager,
    Pdf,
  },
  data() {
    return {
      scenario: null,
      scenarios: null,
      venues: null,
    };
  },
  created() {
    this.getData();
  },

  methods: {
    async getData() {
      await this.axios
        .get(Setting[process.env.NODE_ENV].API + "Venue/GetAll?year=2021")
        .then((response) => {
          this.venues = response.data;
        });

      await this.axios
        .get(Setting[process.env.NODE_ENV].API + "Scenario/GetOne", {
          params: {
            id: this.$route.params.id,
          },
        })
        .then((response) => {
          this.scenario = response.data;

          // console.warn(this.scenario);
        });

      await this.axios
        .get(Setting[process.env.NODE_ENV].API + "Scenario/GetAllByVenue", {
          params: {
            id: this.scenario.parent.id,
          },
        })
        .then((response) => {
          this.scenarios = response.data;
        });
    },
  },

  watch: {
    $route(to, from) {
      /*
      console.warn({
        to,
        from,
      });
      */

      if (from.name == to.name) {
        this.getData();
      }
    },
  },
};

import { Waypoint } from "vue-waypoint";

export default {
  name: "ScenarioPdf",
  components: {
    Waypoint,
  },
  props: {
    data: Object,
  },
  data() {
    return {
      cutter: true,
    };
  },
  created() {},
  methods: {
    cutterOnOff() {
      this.cutter = !this.cutter;
    },
  },
};

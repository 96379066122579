<template>
  <Waypoint>
    <div class="scenario-participants">
      <div class="title-wrapper">
        <div class="placeholder left">
          <img src="@/assets/pin.svg" class="pin-left" />
        </div>
        <div class="title animated-title" v-html="data.title" />
      </div>

      <div class="names-wrapper">
        <div
          class="name"
          v-for="(item, i) in data.names"
          :key="i"
          v-html="item"
        />
      </div>
      <div class="companies">
        <div v-for="item in data.brands" :key="item.logo">
          <img :src="item.logo" />
        </div>
      </div>
    </div>
  </Waypoint>
</template>

<script src="./ScenarioParticipants.js"></script>

<template>
  <Waypoint>
    <div class="scenario-name">
      <div class="scenario-name-wrapper">
        <div class="scenario-name-text animated-left-right" v-html="data" />
      </div>
    </div>
  </Waypoint>
</template>

<script src="./ScenarioName.js"></script>

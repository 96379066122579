<template>
  <Waypoint>
    <div class="scenario-device">
      <div class="title-wrapper">
        <div class="placeholder left">
          <img src="@/assets/pin.svg" class="pin-left" />
        </div>
        <div class="title animated-title" v-html="data.title" />
      </div>

      <div class="devices-wrapper">
        <div
          :class="{
            device: true,
            'is-empty': item.empty,
            odd: i % 2 == 0,
            even: i % 2 != 0,
          }"
          v-for="(item, i) in data.items"
          :key="i"
        >
          <template v-if="item.image">
            <div class="device-name" v-if="item.title" v-html="item.title" />
            <div
              class="device-description"
              v-if="item.description"
              v-html="item.description"
            />
          </template>
          <template v-else>
            <div
              :class="{ 'device-name': true, 'no-image': true }"
              v-if="item.title"
              v-html="item.title"
            />
            <div
              :class="{ 'device-description': true, 'no-image': true }"
              v-if="item.description"
              v-html="item.description"
            />
          </template>

          <div class="device-wrapper">
            <div
              class="device-attributes"
              v-if="
                (item.attributes && item.attributes.length > 0) ||
                (item.list && item.list.length > 0)
              "
            >
              <template v-if="item.attributes">
                <div
                  class="device-attribute"
                  v-for="(attribute, j) in item.attributes"
                  :key="j"
                >
                  <div class="attribute-name">
                    <span
                      v-if="attribute.name"
                      v-html="
                        attribute.name + (attribute.value != '' ? ':' : '')
                      "
                    ></span>
                  </div>
                  <div class="attribute-value" v-html="attribute.value" />
                </div>
              </template>
              <template v-if="item.list">
                <ul>
                  <li
                    class="device-list-item"
                    v-for="(attribute, j) in item.list"
                    :key="j"
                    v-html="attribute"
                  ></li>
                </ul>
              </template>
            </div>
            <template v-if="item.column">
              <div
                class="device-image"
                v-if="item.image"
                :style="{
                  display: 'flex',
                  width: '100%',
                  'justify-content': 'center',
                }"
              >
                <img :src="item.image" :style="{ width: item.column }" />
              </div>
              <div
                class="device-image"
                v-if="item.images"
                :style="{ 'min-width': item.column }"
              >
                <img :src="image" v-for="(image, i) in item.images" :key="i" />
              </div>
            </template>
            <template v-else>
              <div class="device-image" v-if="item.image">
                <img :src="item.image" />
              </div>
              <div class="device-image" v-if="item.images">
                <img :src="image" v-for="(image, i) in item.images" :key="i" />
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </Waypoint>
</template>

<script src="./ScenarioDevice.js"></script>

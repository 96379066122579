<template>
  <div>
    <Header />
    <router-view />
    <div class="back-to-top" v-on:click="backToTop">
      <img alt="back to top" src="@/assets/up-arrow.svg" />
    </div>
  </div>
</template>

<script src="./App.js"></script>

import { Waypoint } from "vue-waypoint";

export default {
  name: "VenueParticipants",
  props: {
    data: Object,
  },
  components: {
    Waypoint,
  },
  created() {},
};

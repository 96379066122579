<template>
  <Waypoint>
    <div class="scenario-diagram">
      <div class="title-wrapper">
        <div class="title animated-title" v-html="data.title" />
        <div class="placeholder right">
          <img src="@/assets/pin-blue.svg" class="pin-right" />
        </div>
      </div>
      <div class="description" v-html="data.description" />
      <div class="image" v-if="data.image">
        <img :src="data.image.url" />
      </div>

      <template v-if="data.extended">
        <div class="extended" v-for="(item, i) in data.extended" :key="i">
          <div class="title" v-html="item.title" v-if="item.title" />
          <div class="image" v-if="item.image">
            <div
              class="title"
              v-html="item.image.title"
              v-if="item.image.title"
            />
            <img :src="item.image.url" />
            <div
              class="extended-image-description description"
              v-html="item.image.description"
              v-if="item.image.description"
            />
          </div>
          <div
            class="description"
            v-html="item.description"
            v-if="item.description"
          />
          <Table :data="item.table" v-if="item.table" />
        </div>
      </template>
    </div>
  </Waypoint>
</template>

<script src="./ScenarioDiagram.js"></script>

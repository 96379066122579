import { Waypoint } from "vue-waypoint";
import Table from "@/components/Table.vue";
import Gallery from "@/components/Gallery.vue";

export default {
  name: "ScenarioIntroduction",
  props: {
    data: Object,
  },
  components: {
    Waypoint,
    Table,
    Gallery,
  },
  created() {},
};

import { Waypoint } from "vue-waypoint";
import modal from "@/components/Modal.vue";

export default {
  name: "VenueContact",
  props: {
    data: Object,
  },
  data() {
    return {
      showModal: false,
      modalType: "CONTACTS",
    };
  },
  components: {
    Waypoint,
    modal,
  },
  created() {},
  methods: {
    onCloseModal() {
      this.showModal = false;
    },
    onShowContacts() {
      this.showModal = true;
    },
  },
};

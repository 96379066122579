export default {
  name: "Table",
  components: {},
  props: {
    data: Object,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};

<template>
  <Waypoint>
    <div class="venue-images">
      <div class="venue-images-wrapper animated-imageset">
        <img v-for="item in data" :key="item.url" :src="item.url" />
      </div>
    </div>
  </Waypoint>
</template>

<script src="./VenueImages.js"></script>

import ScenariosCombo from "@/components/ScenariosCombo.vue";

export default {
  name: "ScenarioPager",
  props: {
    scenarios: Array,
  },
  components: {
    ScenariosCombo,
  },
  created() {
    /*
    console.warn({
      route: this.$route.params.id,
      scenarios: this.scenarios,
    });
    */
  },
  methods: {
    getEnviroment() {
      return process.env.NODE_ENV;
    },
    getPrev() {
      let currentIndex;

      for (let index = 0; index < this.scenarios.length; index++) {
        const x = this.scenarios[index];

        if (x.path == this.$route.params.id) {
          currentIndex = index;
        }
      }

      const scenario = this.scenarios[currentIndex - 1];

      if (!scenario) {
        return null;
      }

      if (
        (this.getEnviroment() == "development" &&
          scenario.development &&
          scenario.active) ||
        (this.getEnviroment() == "production" &&
          scenario.production &&
          scenario.active)
      ) {
        setTimeout(() => {
          document.getElementById("app").scrollIntoView({ behavior: "smooth" });
        }, 500);
        return scenario.path;
      }
    },
    getNext() {
      let currentIndex;

      for (let index = 0; index < this.scenarios.length; index++) {
        const x = this.scenarios[index];

        if (x.path == this.$route.params.id) {
          currentIndex = index;
        }
      }

      const scenario = this.scenarios[currentIndex + 1];

      if (!scenario) {
        return null;
      }

      if (
        (this.getEnviroment() == "development" &&
          scenario.development &&
          scenario.active) ||
        (this.getEnviroment() == "production" &&
          scenario.production &&
          scenario.active)
      ) {
        setTimeout(() => {
          document.getElementById("app").scrollIntoView({ behavior: "smooth" });
        }, 500);
        return scenario.path;
      }
    },
  },
};

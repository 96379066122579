import Video from "@/components/Video.vue";
import Pdf from "@/components/Pdf.vue";
import vueRecaptcha from "vue3-recaptcha2";

export default {
  name: "Modal",
  props: {
    data: Object,
    content: String,
  },
  components: {
    Video,
    Pdf,
    vueRecaptcha,
  },
  data() {
    return {
      source: this,
      showRecaptcha: true,
      loadingTimeout: 30000, // 30 seconds
      isRecaptchaVerified: false,
    };
  },
  methods: {
    onClick(event) {
      if (
        event.target.id == "modal-mask" ||
        event.target.id == "modal-wrapper" ||
        event.target.id == "modal-container" ||
        event.target.id == "close-modal-button"
      ) {
        this.$emit("close");
      }
    },
    recaptchaVerified() {
      /*
      console.warn({
        recaptchaVerified: response,
      });
      */

      this.isRecaptchaVerified = true;
    },
    recaptchaExpired() {
      this.$refs.vueRecaptcha.reset();

      this.isRecaptchaVerified = false;
    },
    recaptchaFailed() {
      /*
      console.warn({
        recaptchaFailed: response,
      });
      */

      this.isRecaptchaVerified = false;
    },
    recaptchaError() {
      /*
      console.warn({
        recaptchaError: reason,
      });
      */

      this.isRecaptchaVerified = false;
    },
  },
};

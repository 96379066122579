<template>
  <Waypoint>
    <div :class="{ video: true, odd: isOdd }">
      <div class="video-wrapper">
        <template v-if="isOdd">
          <div class="title-wrapper">
            <div class="placeholder left">
              <img src="@/assets/pin.svg" class="pin-left" />
            </div>
            <div class="title left animated-title" v-html="data.title" />
          </div>
        </template>
        <template v-if="!isOdd">
          <div class="title-wrapper">
            <div class="title right animated-title" v-html="data.title" />
            <div class="placeholder right">
              <img src="@/assets/pin.svg" class="pin-right" />
            </div>
          </div>
        </template>
        <div class="video-description" v-html="data.description" />
        <div class="video-player">
          <!--
          <video
            :id="data.video.id"
            ref="video_obj"
            autoplay
            class="azuremediaplayer amp-default-skin"
            controls
          ></video>
          -->
          <div id="player-wrapper">
            <player ref="playerRef" :id="data.video.id" />
          </div>
        </div>
        <div :class="{ 'video-text': true, cutter: cutter }">
          <template v-if="data.text">
            <div class="content" v-html="data.text" @click="cutterOnOff()" />
            <div class="read-more" @click="cutterOnOff()">READ MORE</div>
          </template>
        </div>
      </div>
    </div>
  </Waypoint>
</template>

<script src="./Video.js"></script>

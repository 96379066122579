import { Waypoint } from "vue-waypoint";

export default {
  name: "VenueImages",
  props: {
    data: Array,
  },
  components: {
    Waypoint,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
  created() {},
};

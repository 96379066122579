<template>
  <Waypoint>
    <div class="scenario-introduction">
      <div class="title-wrapper">
        <div class="title animated-title" v-html="data.title" />
        <div class="placeholder right">
          <img src="@/assets/pin.svg" class="pin-right" />
        </div>
      </div>
      <div
        class="description"
        v-if="data.description"
        v-html="data.description"
      />
      <div class="image-wrapper">
        <div class="image" v-if="data.image">
          <img :src="data.image.url" />
          <div
            class="image-description description"
            v-html="data.image.description"
          />
        </div>
      </div>
      <div class="introduction-items-wrapper" v-if="data.items">
        <div
          :class="{ item: true, odd: i % 2 == 0, even: i % 2 != 0 }"
          v-for="(item, i) in data.items"
          :key="i"
        >
          <template v-if="!item.colums">
            <div class="item-title" v-if="item.title" v-html="item.title" />
            <div
              class="item-description"
              v-if="item.description"
              v-html="item.description"
            />
            <Table
              v-for="(table, j) in item.tables"
              :key="j"
              :data="table"
            ></Table>
            <div
              class="item-image-image"
              style="display: flex; width: 100%; justify-content: center"
              v-if="item.image"
            >
              <img :src="item.image.url" />
            </div>
            <div class="device-attributes">
              <template v-if="item.attributes">
                <div
                  class="device-attribute"
                  v-for="(attribute, j) in item.attributes"
                  :key="j"
                >
                  <div class="attribute-name">
                    <span
                      v-if="attribute.name"
                      v-html="
                        attribute.name + (attribute.value != '' ? ':' : '')
                      "
                    ></span>
                  </div>
                  <div class="attribute-value" v-html="attribute.value" />
                </div>
              </template>
              <template v-if="item.list">
                <ul>
                  <li
                    class="device-list-item"
                    v-for="(attribute, j) in item.list"
                    :key="j"
                    v-html="attribute"
                  ></li>
                </ul>
              </template>
            </div>
          </template>
          <template v-else>
            <div class="item-title" v-if="item.title" v-html="item.title" />
            <div class="introduction-item-wrapper">
              <div
                class="item-description"
                v-if="item.description"
                v-html="item.description"
              />
              <div
                class="item-image-image"
                style="display: flex; width: 100%; justify-content: center"
                v-if="item.image"
              >
                <template v-if="item.image.link">
                  <a :href="item.image.link" target="_blank"
                    ><img :src="item.image.url"
                  /></a>
                </template>
                <template v-else><img :src="item.image.url" /></template>
              </div>
            </div>
          </template>

          <template v-if="item.imageset && item.imageset.length > 0">
            <div class="images-wrapper">
              <Gallery :data="item.imageset" />
            </div>
          </template>
        </div>
      </div>
    </div>
  </Waypoint>
</template>

<script src="./ScenarioIntroduction.js"></script>

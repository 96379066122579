<template>
  <div
    :class="{
      venues: true,
      _2021_: $route.meta.year == '2021',
      _2022_: $route.meta.year == '2022',
      _2023_: $route.meta.year == '2023',
      _2024_: $route.meta.year == '2024',
    }"
  >
    <template v-if="showBackgroundMap">
      <div class="venues-background"></div>
    </template>
    <template v-if="showScrenariosCombo">
      <ScenariosCombo :scenarios="scenarios" />
    </template>
    <Waypoint @change="onChange">
      <div class="title-wrapper">
        <div class="placeholder left"></div>
        <div class="title" v-html="title" />
        <div class="placeholder right">
          <img
            src="@/assets/pin.svg"
            :class="{ 'pin-right': true, visible: visible }"
          />
        </div>
      </div>
    </Waypoint>
    <Waypoint>
      <div class="venues-wrapper">
        <template v-if="data">
          <div class="row row-1">
            <template
              v-for="(item, i) in data.filter((x) => x.row == 1)"
              :key="i"
            >
              <template v-if="item.active">
                <div
                  :style="'text-decoration: none;'"
                  v-on:click="onClick(item.id)"
                  :class="{
                    venue: true,
                    left: i == 0,
                    right: i == 1,
                  }"
                >
                  <div class="item-wrapper">
                    <div>
                      <template v-if="item.active">
                        <div v-html="item.name" />
                      </template>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <div
                  :to="{ name: 'Venue-' + item.id }"
                  :class="{
                    venue: true,
                    left: i == 0,
                    right: i == 1,
                    'not-active': true,
                  }"
                >
                  <div class="item-wrapper">
                    <div>
                      <div v-html="item.name" class="not-active-venue-name" />
                      <div class="not-active-venue-name-hover">
                        Virtual showcase in preparation
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </template>
          </div>

          <div class="row row-2">
            <template
              v-for="(item, i) in data.filter((x) => x.row == 2)"
              :key="i"
            >
              <template v-if="item.active">
                <div
                  v-on:click="onClick(item.id)"
                  :class="{
                    venue: true,
                    left: i == 0,
                    center: i == 1,
                    right: i == 2,
                  }"
                >
                  <div class="item-wrapper">
                    <div>
                      <template v-if="item.active">
                        <div v-html="item.name" />
                      </template>
                    </div>
                  </div>
                </div>
              </template>
              <template v-if="!item.active">
                <div
                  :to="{ name: 'Venue-' + item.id }"
                  :class="{
                    venue: true,
                    left: i == 0,
                    center: i == 1,
                    right: i == 2,

                    'not-active': true,
                  }"
                >
                  <div class="item-wrapper">
                    <div>
                      <div v-html="item.name" class="not-active-venue-name" />
                      <div class="not-active-venue-name-hover">
                        Virtual showcase in preparation
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </template>
          </div>

          <div class="row row-3">
            <template
              v-for="(item, i) in data.filter((x) => x.row == 3)"
              :key="i"
            >
              <template v-if="item.active">
                <div
                  v-on:click="onClick(item.id)"
                  :class="{
                    venue: true,
                    left: i == 0,
                    right: i == 1,
                  }"
                >
                  <div class="item-wrapper">
                    <div>
                      <template v-if="item.active">
                        <div v-html="item.name" />
                      </template>
                    </div>
                  </div>
                </div>
              </template>
              <template v-if="!item.active">
                <div
                  :to="{ name: 'Venue-' + item.id }"
                  :class="{
                    venue: true,
                    left: i == 0,
                    right: i == 1,

                    'not-active': true,
                  }"
                >
                  <div class="item-wrapper">
                    <div>
                      <div v-html="item.name" class="not-active-venue-name" />
                      <div class="not-active-venue-name-hover">
                        Virtual showcase in preparation
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </template>
          </div>

          <div class="row row-4">
            <template
              v-for="(item, i) in data.filter((x) => x.row == 4)"
              :key="i"
            >
              <template v-if="item.active">
                <div
                  v-on:click="onClick(item.id)"
                  :class="{
                    venue: true,
                    left: i == 0,
                    right: i == 1,
                  }"
                >
                  <div class="item-wrapper">
                    <div>
                      <template v-if="item.active">
                        <div v-html="item.name" />
                      </template>
                    </div>
                  </div>
                </div>
              </template>
              <template v-if="!item.active">
                <div
                  :to="{ name: 'Venue-' + item.id }"
                  :class="{
                    venue: true,
                    left: i == 0,
                    right: i == 1,

                    'not-active': true,
                  }"
                >
                  <div class="item-wrapper">
                    <div>
                      <div v-html="item.name" class="not-active-venue-name" />
                      <div class="not-active-venue-name-hover">
                        Virtual showcase in preparation
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </template>
          </div>

          <div class="row row-5">
            <template
              v-for="(item, i) in data.filter((x) => x.row == 5)"
              :key="i"
            >
              <template v-if="item.active">
                <div
                  v-on:click="onClick(item.id)"
                  :class="{
                    venue: true,
                    left: i == 0,
                    right: i == 1,
                  }"
                >
                  <div class="item-wrapper">
                    <div>
                      <template v-if="item.active">
                        <div v-html="item.name" />
                      </template>
                    </div>
                  </div>
                </div>
              </template>
              <template v-if="!item.active">
                <div
                  :to="{ name: 'Venue-' + item.id }"
                  :class="{
                    venue: true,
                    left: i == 0,
                    right: i == 1,

                    'not-active': true,
                  }"
                >
                  <div class="item-wrapper">
                    <div>
                      <div v-html="item.name" class="not-active-venue-name" />
                      <div class="not-active-venue-name-hover">
                        Virtual showcase in preparation
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </template>
          </div>

          <template v-if="showLogo">
            <div class="row">
              <router-link to="/">
                <img
                  class="venues-logo"
                  alt="O-RAN logo"
                  src="@/assets/logo_plugfest_blue.svg"
                />
              </router-link>
            </div>
          </template>
        </template>
      </div>
    </Waypoint>
  </div>
</template>

<script src="./Venues.js"></script>

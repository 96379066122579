import ScenariosCombo from "@/components/ScenariosCombo.vue";

export default {
  name: "VenueDescription",
  props: {
    data: String,
    scenarios: Array,
  },
  components: {
    ScenariosCombo,
  },
  created() {
    // console.warn(this.data);
  },
};

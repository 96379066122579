import { Waypoint } from "vue-waypoint";
import Table from "@/components/Table.vue";
import modal from "@/components/Modal.vue";
//import imagePDF from "@/assets/PDF.png";

export default {
  name: "ScenariosTable",
  props: {
    data: Object,
  },
  components: {
    Waypoint,
    Table,
    modal,
  },
  data() {
    return {
      openIndex: null,
      showModal: false,
      videoData: null,
      modalType: null,
    };
  },
  created() {},
  methods: {
    getColumn(column, index) {
      /*
      console.warn({
        column: column,
        index: index,
      });
      */

      return (
        column +
        (index == "descriptionShort"
          ? "<div class='read-more'>READ MORE</div>"
          : "")
      );
    },

    onFileClick(event) {
      switch (event.extension) {
        case "PDF":
          this.videoData = {
            pdf: {
              id: "main",
              url: event.path,
            },
          };
          this.modalType = "PDF";
          this.showModal = true;
          break;
        case "MP4":
          this.videoData = {
            video: {
              id: "main",
              url: event.path,
            },
          };
          this.modalType = "VIDEO";
          this.showModal = true;
          break;
        case "PPT":
          window.open(event.path, "_blank");
          break;
      }
    },

    onCloseModal() {
      this.showModal = false;
    },

    onOpen(index) {
      this.openIndex = index;

      this.$forceUpdate();
    },

    onClose() {
      this.openIndex = null;
    },

    getAttachemens(attachements) {
      /*
      console.warn({
        attachements: attachements,
      });
      */

      var result = "";

      for (let index = 0; index < attachements.length; index++) {
        const element = attachements[index];

        var img =
          "<img src='https://oranstorage.blob.core.windows.net/icons/" +
          element.extension +
          ".png' >";

        result = img;
      }

      return result;
    },
    getRow(row) {
      return {
        id: row.id,
        name: row.name,
        lab: row.lab,
        descriptionShort: row.descriptionShort,
        attachements: row.attachements,
      };
    },
  },
};

<template>
  <div class="gallery" :style="getGalleryWidth()">
    <swiper
      v-if="data"
      :modules="modules"
      :slides-per-view="getSliderPerView()"
      :space-between="0"
      navigation
      :pagination="{ clickable: true }"
      @swiper="onSwiper"
      @slideChange="onSlideChange"
    >
      <swiper-slide class="image" v-for="(item, i) in data" :key="i">
        <img :src="item.url" @click="onFullscreen(i)" />
        <div class="gallery-text">{{ item.title }}</div>
      </swiper-slide>
    </swiper>

    <template v-if="isFullscreen">
      <div class="fullscreen-wrapper">
        <div class="swiper-close" @click="onClose(i)">
          <img src="@/assets/close.svg" />
        </div>
        <swiper
          :modules="modules"
          :slides-per-view="1"
          :space-between="0"
          @swiper="onSwiper"
          @slideChange="onSlideChange"
        >
          <!--
          <swiper-slide class="image" v-for="(item, i) in getFullscrenData()" :key="i">
            <img :src="item.full" @click="onFullscreen(i)" />
            <div class="image-title">{{ item.title }}</div>
          </swiper-slide>
        </swiper>
        -->

          <swiper-slide class="image">
            <img
              :class="{ vertical: getFullscrenData(index).vertical }"
              :src="getFullscrenData(index).full"
              @click="onFullscreen(index)"
            />
            <div class="image-title">{{ getFullscrenData(index).title }}</div>
          </swiper-slide>
        </swiper>
      </div>
    </template>
  </div>
</template>

<script src="./Gallery.js"></script>

import ScenariosCombo from "@/components/ScenariosCombo.vue";
import { Waypoint } from "vue-waypoint";

export default {
  name: "Venues",
  props: {
    data: Object,
    scenarios: Array,
    showSwitch: Boolean,
    showScrenariosCombo: Boolean,
    showBackgroundMap: Boolean,
    showLogo: Boolean,
    title: String,
  },
  components: {
    ScenariosCombo,
    Waypoint,
  },
  data() {
    return {
      visible: false,
    };
  },
  setup() {
    const onChange = (waypointState) => {
      // Going can be:
      // IN
      // OUT
      //console.log(waypointState.going);

      switch (waypointState.going) {
        case "IN":
          // this.visible = true;
          break;
        case "OUT":
          // this.visible = false;
          break;
      }

      // Direction can be:
      // UP
      // DOWN
      // LEFT
      // RIGHT
      //console.log(waypointState.direction);
    };

    return { onChange };
  },
  created() {},
  methods: {
    getEnviroment() {
      return process.env.NODE_ENV;
    },
    onClick(id) {
      //setTimeout(() => {
      document.getElementById("app").scrollIntoView({ behavior: "smooth" });
      //}, 1000);

      setTimeout(() => {
        this.$router.push({ name: "Venue-" + id });
      }, 1000);
    },
  },
};

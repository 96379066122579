<template>
  <div class="page venue">
    <PageHeader v-if="venues && venue" :data="venues" :visits="venue.visits" />
    <VenueTitle
      v-if="venue && venue.name"
      :data="venue.name"
      :background="venue.background"
    />
    <VenueDescription
      v-if="venue && scenarios && venue.description"
      :data="venue.description"
      :scenarios="scenarios"
    />

    <template v-if="venue && venue.imageset && venue.imageset.length > 0">
      <Separator backgroundDirection="right" />
      <VenueImages :data="venue.imageset" />
    </template>

    <template v-if="venue && venue.introduction">
      <Separator backgroundDirection="right" />
      <VenueIntroduction :data="venue.introduction" />
    </template>

    <template v-if="venue && venue.introduction && venue.introduction.imageset">
      <Separator backgroundDirection="right" />
      <VenueImages :data="venue.introduction.imageset" />
    </template>
    <template v-if="venue && venue.labs">
      <Separator backgroundDirection="right" />
      <VenueLabs :data="venue.labs" />
    </template>

    <template v-if="venue && venue.participants">
      <Separator backgroundDirection="right" />
      <VenueParticipants :data="venue.participants" />
    </template>

    <Separator backgroundDirection="right" />
    <ScenariosTable
      v-if="venue && venue.scenariosTable"
      :data="venue.scenariosTable"
    />
    <template
      v-if="
        (venue && venue.videos && venue.videos.length > 0) ||
        (venue.pdfs && venue.pdfs.length > 0)
      "
    >
      <Separator backgroundDirection="right" />
      <template v-if="venue && venue.videos">
        <Video
          v-for="(item, i) in venue.videos"
          :key="i"
          :data="item"
          :isOdd="i % 2 == 0"
        />
      </template>
      <template v-if="venue && venue.pdfs">
        <Pdf
          v-for="(item, i) in venue.pdfs"
          :key="i"
          :data="item"
          :isOdd="i % 2 == 0"
        />
      </template>
    </template>

    <template v-if="venue && venue.tip">
      <Separator backgroundDirection="left" />
      <VenueTip :data="venue.tip" />
    </template>

    <template v-if="venue && venue.contacts">
      <Separator backgroundDirection="left" />
      <VenueContact :data="venue.contacts" />
    </template>

    <Separator backgroundDirection="left" />
    <Venues
      v-if="venue && venues && scenarios"
      :data="venues"
      :scenarios="scenarios"
      :showSwitch="false"
      :title="
        'O-RAN Global PlugFest ' +
        ($route.meta.season == null || $route.meta.season == ''
          ? $route.meta.year
          : $route.meta.season)
      "
      :showScrenariosCombo="true"
      :showBackgroundMap="true"
      :showLogo="true"
    />

    <Footer backgroundDirection="right" />
  </div>
</template>

<script src="./Venue.js"></script>

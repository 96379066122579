import ScenariosCombo from "@/components/ScenariosCombo.vue";
import { Waypoint } from "vue-waypoint";

export default {
  name: "PageHeaderScenario",
  props: {
    data: Object,
    venues: Array,
    scenarios: Array,
    visits: Number,
  },
  components: {
    ScenariosCombo,
    Waypoint,
  },

  data() {
    return {
      state: false,
      stateDefault: true,
    };
  },
  methods: {
    getEnviroment() {
      return process.env.NODE_ENV;
    },
    onOpenClose() {
      this.state = !this.state;
      this.stateDefault = false;
    },
  },
  created() {},
};

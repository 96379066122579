<template>
  <Waypoint>
    <div class="pdf">
      <div class="pdf-wrapper">
        <div class="title-wrapper">
          <div class="title animated-title" v-html="data.title" />
          <div class="placeholder right">
            <img src="@/assets/pin.svg" class="pin-right" />
          </div>
        </div>
        <div class="pdf-description" v-html="data.description" />
        <div class="pdf-player">
          <iframe
            frameborder="0"
            allowfullscreen
            style="width: 100%; height: 100%; position: relative"
            :src="data.pdf.url"
          ></iframe>
        </div>
        <div v-if="data.text" :class="{ 'pdf-text': true, cutter: cutter }">
          <template>
            <div class="content" v-html="data.text" @click="cutterOnOff()" />
            <div class="read-more" @click="cutterOnOff()">READ MORE</div>
          </template>
        </div>
        <div v-else :class="{ 'pdf-text': true, 'no-cutter': cutter }"></div>
      </div>
    </div>
  </Waypoint>
</template>

<script src="./Pdf.js"></script>
